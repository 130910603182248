// src/App.js
import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    // Set the website you want to redirect to
    const redirectUrl = 'https://www.astrazeneca.com';
    //https://www.astrazeneca.com/
    //https://www.astrazenecaplc-uk.com/
    // Redirect after component renders
    window.location.href = redirectUrl;
  }, []);

  return (
    <div>
    
    </div>
  );
}

export default App;
